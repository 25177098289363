let additional = 3;
const Product_List = [
    {
        "name": "Performance Inventory Solution",
        "description": "Physical inventory management solution. Features barcode Scanner, powerful features to ensure correctness and efficiency, user systems, clean ui and more.",
        "features": [
            "Powerful concurrent sessions", 
            "gaurds against partial scans", 
            "tracks discrempancies and gives you the tools to manage and correct them", 
            "intuitive to use", 
            "fast and cost effective way to mobilize your workforce",
            "accurate results"
        ],
        "pricing": {
            "base_price": 69,
            "additional_stores": 15 * additional
        },
        "imageUrl": "/static/screenshots/ecommdash1.png",
        "url": "/products/performance_inventory",
        "category": ["physical store", "commerce", "inventory", "business tools"]
    },
    // {
    //     "name": "Example Product",
    //     "description": "this is just a bunch of hooblah about a random product that doesnt exist. This is just here for testing.",
    //     "features": [
    //         "Powerful concurrent sessions", 
    //         "gaurds against partial scans", 
    //         "tracks discrempancies and gives you the tools to manage and correct them", 
    //         "intuitive to use", 
    //         "fast and cost effective way to mobilize your workforce",
    //         "accurate results"
    //     ],
    //     "pricing": {
    //         "base_price": 69,
    //         "additional_stores": 420 * additional
    //     },
    //     "imageUrl": "/images/inventory_apps.jpg",
    //     "url": "/products/example",
    //     "category": ["online", "ecommerce", "crm", "business tools"]
    // },
    // {
    //     "name": "Example Product 2",
    //     "description": "this is just a bunch of hooblah about a random product that doesnt exist. This is just here for testing.",
    //     "features": [
    //         "Powerful concurrent sessions", 
    //         "gaurds against partial scans", 
    //         "tracks discrempancies and gives you the tools to manage and correct them", 
    //         "intuitive to use", 
    //         "fast and cost effective way to mobilize your workforce",
    //         "accurate results"
    //     ],
    //     "pricing": {
    //         "base_price": 69,
    //         "additional_stores": 420 * additional
    //     },
    //     "imageUrl": "/images/inventory_apps.jpg",
    //     "url": "/products/example",
    //     "category": ["online", "ecommerce", "crm", "business tools"]
    // }
]
 export default Product_List; 