import React from 'react'
import { Box, Flex, Heading, VisuallyHidden } from '@chakra-ui/react'
import { Link } from '@saas-ui/react'

export interface LogoProps {
  href?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

import siteConfig from 'data/config'

export const Logo = ({ href = '/', onClick }: LogoProps) => {
  let logo: string | number | boolean | JSX.Element | React.ReactFragment | null | undefined
  if (siteConfig.logo) {
    logo = <Box as={siteConfig.logo} height="32px" mt="-4px" />
  } else {
    logo = (
      <Heading as="h1" size="md">
        {siteConfig.seo?.title}
      </Heading>
    )
  }

  return (
    <Flex h="8" flexShrink="0" alignItems="flex-end">
      <Link href={href} display="flex" p="1" borderRadius="sm" onClick={onClick}>
        {logo}
        <VisuallyHidden>{siteConfig.seo?.title}</VisuallyHidden>
      </Link>
    </Flex>
  )
}
