import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Header, HeaderProps } from './header'
import {
  AnnouncementBanner,
  AnnouncementBannerProps,
} from '../announcement-banner'
import { Footer, FooterProps } from './footer'

interface LayoutProps {
  children: ReactNode
  announcementProps: AnnouncementBannerProps
  headerProps: HeaderProps
  footerProps: FooterProps
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { children, announcementProps, headerProps, footerProps } = props
  return (
    <Box>
      <AnnouncementBanner {...announcementProps} />
      <Header {...headerProps} />
        {children}
      <Footer {...footerProps} />
    </Box>
  )
}
